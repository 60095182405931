import * as React from "react";
import { useIntl } from "gatsby-plugin-intl";

const Page: React.FC = () => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <div style={{ minHeight: "500px", padding: "50px 100px" }}>
        <h1 data-testid='errorpage:title'>
          {intl.formatMessage({
            id: "error-page.header",
          })}
        </h1>
        <p data-testid='errorpage:description'>
          {intl.formatMessage({
            id: "error-page.description",
          })}
        </p>
      </div>
    </React.Fragment>
  );
};

export default Page;